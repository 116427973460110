import React from 'react';
import Layout from '../gatsby-theme-blog/components/layout';
import SEO from "../components/seo";

export default ({ pageContext, location }) => {
	const seo = {
		pageTitle: "Contact",
		pageDescription: "How to contact us at CourseMaker"
	};
	return (
	<Layout>
		<SEO {...seo} />
		<section>
				<div id="row-1" className="py-16 bg-indigo-100 md:py-24">
					<div className="container">
						<h2 className="mb-8 md:mb-10">Contact</h2>
						<p>Questions? Email chris@coursemaker.org</p>
					</div>
				</div>
			</section>

	</Layout>
	);
};
